import { defineStore } from "pinia";
import { useSupabase } from "~/utils";
import { useUserStore } from "./user";

export const useUserInfoStore = defineStore("userInfo", () => {
    const userStore = useUserStore();
    const { user } = storeToRefs(useUserStore());
    const supabase = useSupabase();
    const { $locally } = useNuxtApp();

    const userInfo = ref<UserInfo>();

    watch(user, async () => {
        if (!user.value) {
            userInfo.value = undefined;
            return;
        }

        await setUserInfo();
    });

    async function createUserInfo() {
        if (!userStore.user) return;

        const points = $locally.getItem("points");
        const statistics = $locally.getItem("userStats");

        const { data: existingData } = await supabase
            .from("user_infos")
            .select()
            .eq("user_id", userStore.user.id);

        if (!existingData || !existingData.length) {
            const { data } = await supabase
                .from("user_infos")
                .insert({
                    user_id: userStore.user.id,
                    points: points ?? 0,
                    statistics: statistics ? JSON.parse(statistics) : undefined,
                    username: userStore.user.user_metadata.full_name,
                    photo_url: userStore.user.user_metadata.avatar_url,
                })
                .select();

            if (data && data.length) {
                userInfo.value = data[0] as UserInfo;
            }
        } else {
            userInfo.value = existingData[0] as UserInfo;
        }

        $locally.removeItem("points");
        $locally.removeItem("userStats");
    }

    async function setUserInfo() {
        if (!userStore.user) return;

        const { data } = await supabase
            .from("user_infos")
            .select()
            .eq("user_id", userStore.user.id);

        if (data && data.length) {
            userInfo.value = data[0] as UserInfo;

            $locally.removeItem("points");
            $locally.removeItem("userStats");
        }
    }

    async function updateUserInfo(updatedUserInfo: Partial<UserInfo>) {
        if (!userStore.user) return;

        const { data } = await supabase
            .from("user_infos")
            .update(updatedUserInfo)
            .eq("user_id", userStore.user.id)
            .select();

        if (data && data.length) {
            userInfo.value = data[0] as UserInfo;
        }
    }

    function clearUserInfo() {
        userInfo.value = undefined;
    }

    return {
        userInfo,
        createUserInfo,
        setUserInfo,
        updateUserInfo,
        clearUserInfo,
    };
});
