export default defineAppConfig({
    ui: {
        primary: "darkGreen",
        gray: "cool",
        notifications: {
            // Show toasts at the top right of the screen
            position: "top-0 bottom-auto",
        },
    },
});
